<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editVatRate"
    :options="{
      properties: {
        name: '',
        value: 0.00,
      },
      postUri: '/vat_rates',
      label: $t('vatRate.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" />
    <fancy-input
      v-model="properties.value"
      :label="$t('general.field.value')"
      type="number"
      :min="0"
      :max="1"
      :step="0.0001"
      :error="errors.value"
    />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';
import useLoadOptions from '@/hooks/api/loadOptions';

export default {
  name: 'EditVatRateModal',
  components: {
    FancyInput,
    ModalForm,
    Multiselect,
    ProductIcon,
  },
  setup() {
  },
};
</script>

<style scoped lang="scss">
.option-icon {
  max-width: 40px;
  max-height: 90%;
}
</style>
